<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.trusted_third_party_optional') }}</h1>
      </header>

      <div class="mb-4">
        <p>
          {{ $t('message.trusted_third_party_info') }}
        </p>
      </div>
      <div class="mb-4" v-if="this.id">
        <p>
          {{ $t('message.you_have_designated_as_trusted') }} <strong>{{ firstName }} {{ lastName }}</strong>.
        </p>
        <br>
      </div>
      <div id="trusted_form">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <v-text-field
            v-model="lastName"
            :rules="lastNameRules"
            :label="$t('message.last_name') + '*'"
            required
          ></v-text-field>

          <v-text-field
            v-model="firstName"
            :rules="firstNameRules"
            :label="$t('message.first_name') + '*'"
            required
          ></v-text-field>

          <v-text-field
            v-model="address"
            :rules="addressRules"
            :label="$t('message.address') + '*'"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            :rules="emailRules"
            :label="$t('message.email') + '*'"
            required
          ></v-text-field>

          <button type="submit" :disabled="!valid" class="btn btn-primary float-right mt-4">
            <i class="fas fa-check-circle"></i>{{ $t('message.validate_information') }}
          </button>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'TrustedContact',
  data: () => ({
    valid: false,
    id: null,
    successMessage: null,
    loading: false,
    errors: [],
    lastName: '',
    firstName: '',
    address: '',
    email: ''
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.getTrustedContact()
    document.title = config.title + ' - ' + this.$t('message.trusted_third_party')
  },
  computed: {
    firstNameRules: function () {
      return [
        v => !!v || this.$t('message.mandatory_first_name')
      ]
    },
    lastNameRules: function () {
      return [
        v => !!v || this.$t('message.mandatory_last_name')
      ]
    },
    addressRules: function () {
      return [
        v => !!v || this.$t('message.mandatory_address')
      ]
    },
    emailRules () {
      return [
        v => !!v || this.$t('message.mandatory_email'),
        v => /.+@.+\..+/.test(v) || this.$t('message.mandatory_valid_email')
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.id && this.valid) {
        this.putTrustedContact()
      } else if (!this.id && this.valid) {
        this.postTrustedContact()
      }
    },
    getTrustedContact () {
      const trustedContact = this.$store.getters.getUser.trustedContact

      if (!trustedContact) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/trusted_contacts/' + trustedContact.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const trustedContact = response.data

            if (trustedContact) {
              this.id = trustedContact.id
              this.lastName = trustedContact.lastName
              this.firstName = trustedContact.firstName
              this.address = trustedContact.address
              this.email = trustedContact.email
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false
        })
    },
    postTrustedContact () {
      const user = this.$store.getters.getUser
      const userId = user.id

      const data = {
        lastName: this.lastName,
        firstName: this.firstName,
        address: this.address,
        email: this.email.toLowerCase(),
        user: {
          id: userId
        }
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/trusted_contacts', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const trustedContact = response.data

            if (trustedContact) {
              this.id = trustedContact.id
              this.lastName = trustedContact.lastName
              this.firstName = trustedContact.firstName
              this.address = trustedContact.address
              this.email = trustedContact.email

              user.trustedContact = trustedContact

              this.$store.commit('setUser', user)

              this.successMessage = this.$t('message.trusted_third_party_created')
              this.errors = []
              this.loading = false
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    },
    putTrustedContact () {
      const user = this.$store.getters.getUser

      const data = {
        lastName: this.lastName,
        firstName: this.firstName,
        address: this.address,
        email: this.email.toLowerCase(),
        user: {
          id: user.id
        }
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/trusted_contacts/' + this.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const trustedContact = response.data

            if (trustedContact) {
              user.trustedContact = trustedContact

              this.$store.commit('setUser', user)

              this.successMessage = this.$t('message.trusted_third_party_updated')
              this.errors = []
            }

            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    }
  }
}
</script>
